<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      :showAdd="$hasAccess('market.qycard/add')"
      addName="新增"
      @getList="getList"
      @clearSearch="clearSearch"
      @addItemInTable="addOne"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.coupon_name"
          size="small"
          clearable
          placeholder="按名称查询"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-input
                v-model="searchFormData.phone"
                size="small"
                clearable
                placeholder="按管理员账号查询"
        ></el-input>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
              slot="table-item"
              field="qy_name"
              title="企业名称"
              align="center"
              min-width="100px"
      />
      <vxe-table-column
              slot="table-item"
              field="username"
              title="后台账户"
              align="center"
              min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="phone"
        title="管理员账户"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
              slot="table-item"
              field="num"
              title="成员人数"
              align="center"
              min-width="50px"
      />
      <vxe-table-column
              slot="table-item"
              field="electric"
              title="账户电量"
              align="center"
              min-width="50px"
      />
      <vxe-table-column
              slot="table-item"
              field="valid_time"
              title="有效期"
              align="center"
              min-width="50px"
      />
      <vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" min-width="80px"/>
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="400px"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="editOut(row)"
            v-if="$hasAccess('market.qycard/qyrecharge')"
          >充值</el-button>
          <el-button
                  size="small"
                  plain
                  @click="editMem(row)"
                  v-if="$hasAccess('market.qycard/addmem')"
          >添加成员</el-button>
          <el-button
                  size="small"
                  plain
                  @click="detail(row)"
                  v-if="$hasAccess('market.qycard/qydetail')"
          >人员明细</el-button>
          <el-button
            size="small"
            plain
            @click="deleteOne(row)"
          >删除</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 新增 -->
    <el-dialog
      title="新增企业"
      :visible.sync="addDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="addDialogFormData"
        :rules="rules"
        ref="addForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
          label="企业名称"
          prop="coupon_name"
        >
          <el-input
            v-model="addDialogFormData.qy_name"
            style="width: 80%;"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="绑定后台账户"
                prop="group_id"
        >
          <el-select
                  v-model="addDialogFormData.user_id"
                  filterable
                  size="small"
                  remote
                  reserve-keyword
                  clearable
                  placeholder="请选择账户"
                  v-if="$hasAccess('device.group/view')"
                  :remote-method="remoteGroup"
                  :loading="groupLoading">
            <el-option
                    v-for="item in groupList"
                    :key="item.user_id"
                    :label="item.username"
                    :value="item.user_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
                label="管理员账号"
                prop="phone"
        >
          <el-input
                  v-model="addDialogFormData.phone"
                  style="width: 80%;"
          ></el-input>
        </el-form-item>
<!--        <el-form-item-->
<!--                label="开始时间"-->
<!--                prop="select_time"-->
<!--                v-if="addDialogFormData.coupon_type==20"-->
<!--        >-->
<!--          <el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time" type="datetime" placeholder="开启时间" ></el-date-picker>-->
<!--        </el-form-item>-->
<!--        <el-form-item-->
<!--                label="结束时间"-->
<!--                prop="select_time2"-->
<!--                v-if="addDialogFormData.coupon_type==20"-->
<!--        >-->
<!--          <el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time2" type="datetime" placeholder="结束时间"></el-date-picker>-->
<!--        </el-form-item>-->
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveAdd()"
          v-if="$hasAccess('market.qycard/add')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑企业"
      :visible.sync="editDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="editDialogFormData"
        :rules="rules"
        ref="editForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
          label="优惠名称"
          prop="coupon_name"
        >
          <el-input
            v-model="editDialogFormData.coupon_name"
            style="width: 80%;"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="优惠券类型"
                prop="type"
        >
          <el-radio-group v-model="editDialogFormData.type">
            <el-radio :label="10">折扣</el-radio>
            <el-radio :label="20">现金</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
                label="使用类型"
                prop="type"
        >
          <el-radio-group v-model="editDialogFormData.grouptype">
            <el-radio :label="10">通用</el-radio>
            <el-radio :label="20">指定站点</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
                label="发放小区"
                prop="groupIds"
                v-if="editDialogFormData.grouptype==20"
        >
          <el-select
                  v-model="groupIds"
                  filterable
                  size="small"
                  remote
                  reserve-keyword
                  clearable
                  placeholder="请选择小区"
                  v-if="$hasAccess('device.group/view')"
                  :remote-method="remoteGroup"
                  :loading="groupLoading">
            <el-option
                    v-for="item in groupList"
                    :key="item.group_id"
                    :label="item.group_name"
                    :value="item.group_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="折扣" prop="coupon_discount" v-if="editDialogFormData.type==10">
          <el-input-number :min="1" :max="9.5" :step="0.1" :precision="1" v-model="editDialogFormData.coupon_discount" clearable />
        </el-form-item>
        <el-form-item label="最多优惠（元）" prop="max_money" v-if="editDialogFormData.type==10">
          <el-input-number :min="0"  :step="1"  v-model="editDialogFormData.max_money" clearable />
        </el-form-item>
        <el-form-item label="现金优惠（元）" prop="max_money" v-if="editDialogFormData.type==20">
          <el-input-number :min="0"  :step="1"  v-model="editDialogFormData.max_money" clearable />
        </el-form-item>
        <el-form-item
          label="有效期类型"
          prop="coupon_type"
        >
          <el-radio-group v-model="editDialogFormData.coupon_type">
            <el-radio :label="10">限时</el-radio>
            <el-radio :label="20">普通</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
                label="开始时间"
                prop="select_time"
                v-if="editDialogFormData.coupon_type==20"
        >
          <el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.start_time" type="datetime" placeholder="开启时间" ></el-date-picker>
        </el-form-item>
        <el-form-item
                label="结束时间"
                prop="select_time2"
                v-if="editDialogFormData.coupon_type==20"
        >
          <el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.end_time" type="datetime" placeholder="结束时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="有效天数" prop="day" v-if="editDialogFormData.coupon_type==10">
          <el-input-number :min="1"  :step="1"  v-model="editDialogFormData.day" clearable />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveEdit()"
          :disabled="editDisabled"
          v-if="$hasAccess('market.coupon/edit')"
        >保 存 编 辑
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
    <!-- 充值 -->
    <el-dialog
            title="充值"
            :visible.sync="outDialogShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="500px"
    >
      <el-form
              :model="outDialogFormData"
              :rules="rules"
              ref="editForm"
              label-width="100px"
              class="form"
      >
        <el-form-item label="电量" prop="electric">
          <el-input-number
                  :min="1"  :step="100"
                  v-model="outDialogFormData.electric" clearable />
        </el-form-item>
        <el-form-item
                label="有效期至"
                prop="select_time"
        >
          <el-date-picker class="from-item-width" unlink-panels v-model="outDialogFormData.select_time" type="datetime"
                          placeholder="请选择有效期"></el-date-picker>
        </el-form-item>
      </el-form>
      <div
              slot="footer"
              class="dialog-footer"
      >
        <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-check"
                @click="saveOut()"
                :disabled="editDisabled"
                v-if="$hasAccess('market.qycard/qyrecharge')"
        >提 交
        </el-button>
        <el-button
                size="small"
                plain
                icon="el-icon-close"
                @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
    <!-- 添加成员 -->
    <el-dialog
            title="添加成员"
            :visible.sync="memDialogShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="500px"
    >
      <el-form
              :model="memDialogFormData"
              :rules="rules"
              ref="editForm"
              label-width="100px"
              class="form"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input
                  style="width: 50%;"
                  v-model="memDialogFormData.phone" clearable />
        </el-form-item>
        <el-form-item label="电量" prop="electric">
          <el-input-number
                  :min="1"  :step="100"
                  v-model="memDialogFormData.electric" clearable />
        </el-form-item>
      </el-form>
      <div
              slot="footer"
              class="dialog-footer"
      >
        <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-check"
                @click="saveMem()"
                :disabled="editDisabled"
                v-if="$hasAccess('market.qycard/addmem')"
        >提 交
        </el-button>
        <el-button
                size="small"
                plain
                icon="el-icon-close"
                @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'market-coupon-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '充值优惠',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      selecttime: {},
      groupList: [],
      groupIds: [],
      types: {
        coupon_type: {
          10: '限时',
          20: '普通'
        },
        type: {
          10: '折扣',
          20: '现金'
        },
        grouptype: {
          10: '通用',
          20: '指定站点'
        }
      },
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      groupLoading:false,
      rules: {
        qy_name: [
          { required: true, message: '请输入企业名称', trigger: 'change' }
        ]
      },
      // 编辑
      editDialogShow: false,
      outDialogShow:false,
      memDialogShow:false,
      editDialogFormData: {},
      outDialogFormData: {},
      memDialogFormData:{},
      editDialogFormOriData: {},
      editNeedData: ['coupon_id', 'coupon_name', 'coupon_money', 'coupon_type','type','grouptype','group_id', 'gift_money']
    }
  },
  computed: {
    editDisabled () {
      for (let item of this.editNeedData) {
        if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
          return false
        }
      }
      return false
    }
  },
  mounted () {
    this.init();
  },
  activated () {
    this.init();
  },
  methods: {
    // 初始化请求
    init() {
      this.remoteGroup();
    },
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Market.GetQycardList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    async remoteGroup (query) {
      if (this.$store.state.user.userInfo.role.role_id == 8) {
         return  ;
      }
      if (query !== '') {
        this.groupLoading = true
        const params = {
          token: this.$store.state.user.token,
          page: 1,
          size: 1000
        }
        const res = await this.$api.Device.GetUserList(params, {
          username: query
        })
        this.groupList = res.data
        this.groupLoading = false
      } else {
        this.groupList = []
      }
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {}
    },
    // 重置form
    resetFormData () {
      this.addDialogFormData = {

      }
      this.groupIds = [];
      this.editDialogFormData = {}
      this.editDialogFormOriData = {}
      this.outDialogFormData = {}
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    // 编辑
    async editOne (row) {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        coupon_id: row.coupon_id
      }
      const res = await this.$api.Market.GetCouponDetail(params)
      if (res) {
        const { coupon_id, coupon_name, coupon_discount, max_money, coupon_type,type,grouptype,group_id,start_time,end_time,day } = res
        this.editDialogFormData = { coupon_id, coupon_name, coupon_discount, max_money,type,grouptype, group_id,coupon_type,start_time,end_time,day }
        this.editDialogFormOriData = { coupon_id, coupon_name, coupon_discount, max_money, type,grouptype,group_id,coupon_type,start_time,end_time,day }

        if (res.group_id) {
          const params = {
            token: this.$store.state.user.token,
            size: 1000
          }
          this.$api.Device.GetGroupList(params).then(gres => {
            this.groupList = gres.data
            const groupIds = res.group_id.toString().split(',')
            for (let i = 0; i < groupIds.length; i++) {
              groupIds[i] = parseInt(groupIds[i])
            }
            this.groupIds = groupIds
          })
        }

      }
      this.editDialogShow = true
    },
    //充值
    async editOut (row) {
      this.resetFormData();
      this.outDialogFormData.id=row.id;
      this.outDialogShow = true
    },
    //添加成员
    async editMem (row) {
      this.resetFormData()
      this.memDialogFormData.id=row.id;
      this.memDialogFormData.main_id=row.main_id;
      this.memDialogFormData.valid_time=row.valid_time;
      this.memDialogShow = true
    },
    // 编辑设备
    detail(row) {
      this.$router.push({
        name: "market-qycard-detail",
        query: {
          qy_id: row.id,
        },
      });
    },
    // 删除
    deleteOne (row) {
      this.$confirm('确定要删除该企业吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          id: row.id
        }
        console.log(params);
        await this.$api.Market.DeleteQy(params)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
      this.outDialogShow = false
      this.memDialogShow = false
    },
    // 新增保存
    saveAdd () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          // if (this.addDialogFormData.select_time!=undefined) {
          //   this.addDialogFormData.start_time = parseInt(this.addDialogFormData.select_time.getTime() / 1000);
          //   this.addDialogFormData.end_time = parseInt(this.addDialogFormData.select_time2.getTime() / 1000);
          // }
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.addDialogFormData)
          }
          await this.$api.Market.AddQy(params)
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },
    // 编辑保存
    saveEdit () {
      this.editDialogFormData.group_id = this.groupIds.join(',')
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          if (this.editDialogFormData.coupon_type == 20) {
            this.selecttime.start_time = this.editDialogFormData.start_time.length == undefined ? parseInt(this.editDialogFormData.start_time.getTime() / 1000) : this.editDialogFormData.start_time;
            this.selecttime.end_time = this.editDialogFormData.end_time.length == undefined ? parseInt(this.editDialogFormData.end_time.getTime() / 1000) : this.editDialogFormData.end_time;
            this.editDialogFormData.start = this.selecttime.start_time;
            this.editDialogFormData.end = this.selecttime.end_time;
          }
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.editDialogFormData)
          }
          await this.$api.Market.EditCoupon(params)
          this.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },
    // 企业卡充值
    saveOut () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          this.outDialogFormData.valid_time = this.outDialogFormData.select_time.length == undefined ? parseInt(this.outDialogFormData.select_time.getTime() / 1000) : this.outDialogFormData.select_time;
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.outDialogFormData)
          }
          await this.$api.Market.QyRecharge(params)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },
    // 添加成员
    saveMem () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.memDialogFormData)
          }
          await this.$api.Market.QyMem(params)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
